<template>
  <header
    class="bg-white w-full sticky z-40 print:hidden sm:relative"
    :class="{ 'sm:hidden': onHomePage }"
  >
    <div class="flex site-header">
      <portal v-if="show_visualiser_modal" to="portal_popup">
        <save-visualisation
          :show-modal="show_visualiser_modal"
          @close="show_visualiser_modal = false"
        />
      </portal>
      <div v-if="branded_mode_user" class="flex flex-grow">
        <Logo
          v-if="!inIframe()"
          class="border-b sm:border-r"
          :sidebar-visible="sidebarVisible"
          :src="style.customer_logo"
          :customer-name="style.customer_name"
          :href="branded_mode_settings.webAddress"
        ></Logo>
        <div
          v-show="branded_mode_settings.phone"
          class="sm:flex flex-col space-around border-b flex-grow"
        >
          <div
            class="hidden 2xs:block my-auto px-2 mx-2 md:px-10 md:mx-0 2xs:py-4 sm:whitespace-no-wrap text-xs 2xs:text-sm"
          >
            <span
              :class="{
                'hidden sm:inline': (hasPricing && onDesignerPage) || visualiser_place_page,
              }"
            >Need help? Call us on <strong> {{ branded_mode_settings.phone }} </strong>
            </span>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-grow">
        <router-link to="/">
          <Logo
            class="border-b xs:border-r"
            :sidebar-visible="sidebarVisible"
            :src="style.fabricator_logo"
            :customer-name="style.customer_name"
          >
          </Logo>
        </router-link>
        <div class="flex flex-grow flex-col border-b sm:border-b-0">
          <div>
            <slot name="header_page_breadcrumb"></slot>
          </div>
          <div class="hidden sm:block flex-grow">
            <slot name="site_header_actions_wrapper">
              <div class="flex border-b flex-1 h-full">
                <div
                  class="flex flex-col justify-around"
                  :class="{ 'hidden  xs:flex': showSearch }"
                >
                  <div class="text-lg font-medium px-3 py-2 sm:text-xl md:text-2xl md:px-10">
                    <slot name="header_page_title"></slot>
                  </div>
                </div>
                <div
                  v-show="showSearch"
                  class="flex flex-col justify-around flex-grow px-3"
                  style="max-width: 600px"
                >
                  <portal-target class="block" name="portal_search"></portal-target>
                </div>
                <div class="ml-auto flex flex-col justify-around px-3 py-2">
                  <slot name="header_page_actions"></slot>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
      <div class="flex">
        <div
          v-if="visualiser_place_page"
          class="text-center hidden xs:flex justify-around pl-4 pr-4 flex-col border-l border-b"
        >
          <button class="btn-action btn-lg whitespace-no-wrap" @click="saveVisualisation()">
            Save Visualisation
          </button>
        </div>
        <header-save-to-quote v-if="onDesignerPage" class="hidden xs:flex"></header-save-to-quote>
        <basket-popup
          v-if="showBasket"
          :not_on_basket_page="notOnBasketPage"
          :on_home_page="onHomePage"
          :on_designer_page="onDesignerPage"
        ></basket-popup>
        <a
          v-if="onDesignerPage && !branded_mode_user"
          class="flex flex-col justify-center p-4 text-center border-l border-b cursor-pointer h-full w-16 sm:w-32"
          @click="designAnother()"
        >
          <div class="text-2xl">
            <i class="fal fa-repeat-alt"></i>
          </div>
          <div class="hidden sm:block text-sm capitalize mt-3">
            <div>Switch</div>
            <div>Product</div>
          </div>
          <info-popup
            :id="'designer_header:switch_product'"
            class="absolute right-0 bottom-0"
            :info_message="'Chosen the wrong product? Click here to change the product.'"
            :next_id="'designer_header:price_breakdown'"
          ></info-popup>
        </a>
        <menu-popup v-if="showPopupMenu" class="sm:hidden"></menu-popup>
        <a
          v-if="branded_mode_user && !inIframe()"
          :href="branded_mode_settings.webAddress"
          class="flex flex-col justify-center p-4 text-center border-l border-b cursor-pointer h-full w-16 sm:w-32"
        >
          <div class="text-2xl">
            <i class="fal fa-browser"></i>
          </div>
          <div class="hidden sm:block text-sm capitalize mt-3">
            <div>Back To</div>
            <div>Website</div>
          </div>
        </a>
      </div>
    </div>
    <div
      v-if="!['/portal', '/business'].includes($route.path) && !branded_mode_user"
      class="sm:hidden"
    >
      <slot name="site_header_actions_wrapper">
        <div class="w-full flex border-b flex-1 h-full py-2">
          <div class="flex flex-col justify-around" :class="{ 'hidden  xs:flex': showSearch }">
            <div class="text-lg font-medium px-3 py-2 sm:text-xl md:text-2xl md:px-10">
              <slot name="header_page_title"></slot>
            </div>
          </div>
          <div
            v-show="showSearch"
            class="flex flex-col justify-center flex-grow px-3 mx-auto"
            style="max-width: 600px"
          >
            <portal-target class="block" name="portal_search"></portal-target>
          </div>
          <div class="ml-auto flex flex-col justify-around">
            <slot name="header_page_actions"></slot>
          </div>
        </div>
      </slot>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import SaveVisualisation from '@/components/branded/visualiser/SaveVisualisation.vue';
import BasketPopup from '@/components/shared/nav/BasketPopup.vue';
import HeaderSaveToQuote from '@/components/shared/nav/HeaderSaveToQuote.vue';
import Logo from '@/components/shared/nav/Logo.vue';
import MenuPopup from '@/components/shared/nav/MenuPopup.vue';

export default {
  name: 'SiteHeader',
  components: {
    'basket-popup': BasketPopup,
    'header-save-to-quote': HeaderSaveToQuote,
    'save-visualisation': SaveVisualisation,
    Logo,
    MenuPopup
  },
  props: ['sidebarVisible', 'visualiser_place_page', 'show_site_header_actions_wrapper'],
  data() {
    return {
      show_visualiser_modal: false,
    };
  },
  computed: {
    requiresMeasurements() {
      return this.$store.getters['basket/currentItem'].dimensions.some(
        (dimension) => dimension.requiresInput && !dimension.hasBeenInput,
      );
    },
    requiresInput() {
      return this.$store.getters['basket/currentItem'].specification.some(
        (spec) => spec.mustBeSelected && !spec.hasBeenSelected,
      );
    },
    showBasket() {
      return this.authenticated_user && !this.touch_business_user && this.$route.name !== 'Job';
    },
    showPopupMenu() {
      return this.authenticated_user && !this.branded_mode_user;
    },
    showDashboardLink() {
      return this.authenticated_user && !this.branded_mode_user;
    },
    showSearch() {
      return this.$route.meta.showSearch === true;
    },
    onHomePage() {
      return this.$route.name === 'Portal Dashboard' || this.$route.name === 'Admin Dashboard';
    },
    onDesignerPage() {
      return this.$route.name === 'Designer' || this.$route.name === 'Retail Designer';
    },
    onChooseProductPage() {
      return this.$route.name === 'Choose Product' || this.$route.name === 'Retail Choose Product';
    },
    notOnBasketPage() {
      return (
        this.$route.name !== 'Basket' &&
        this.$route.name !== 'Checkout' &&
        this.$route.name !== 'Retail Basket' &&
        this.$route.name !== 'Thank You'
      );
    },
    hasPricing() {
      return !this.branded_mode_user || this.$store.getters['user/hasBrandedPricingOn'];
    },
    ...mapGetters({
      basketItemCount: 'basket/itemCount',
      brandedChooseProductURL: 'branded/chooseProductURL',
    }),
    ...mapState('basket', ['basket']),
  },
  methods: {
    inIframe () {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    saveVisualisation() {
      if (this.branded_mode_user) {
        this.show_visualiser_modal = true;
      } else {
        this.$store.dispatch('visualiser/saveToServer');
        const basketUrl = this.touch_business_installation
          ? `/customer/${this.$store.state.basket.customerId}/sales-dashboard/basket`
          : '/basket'
        this.$router.push(basketUrl).then(() => {
          this.alertBox().fire({
            title: 'Visualisation Saved',
            text: 'Visualisation Saved to Quote.  Please complete your quote as usual.',
          });
        });
      }
    },
    designAnother() {
      this.alertBox()
        .fire({
          customClass: {
            cancelButton: 'absolute top-0 right-0 p-3',
            confirmButton: 'btn-action m-1',
            denyButton: 'btn m-1',
          },
          title: 'Switch Product',
          text: 'Would you like to go back and select a different product?',
          icon: 'question',
          className: 'relative',
          showCancelButton: true,
          showDenyButton: true,
          denyButtonText: 'Yes, and delete my current product',
          confirmButtonText: 'Yes, but save my current product',
          cancelButtonText: '<i class="fal fa-times"></i>',
        })
        .then(async (result) => {
          if (result.isDismissed) {
            return;
          }

          if (!result.isConfirmed) {
            this.$store.dispatch('basket/deleteLineItem', {
              lineItemId: this.$route.params.id,
            });
          } else if (this.requiresInput) {
            this.alertBox().fire(
              'You must answer the required questions before adding this to your basket',
            );
            this.$store.commit('designer/SET_OPEN_MENU_TYPE', 'specification');
            return;
          } else if (this.requiresMeasurements) {
            this.alertBox().fire('You must supply dimensions before adding this to your basket');
            this.$store.commit('designer/SET_OPEN_MENU_TYPE', 'sizes');
            return;
          }

          if (this.branded_mode_user) {
            this.routerPush(this.brandedChooseProductURL);
          } else if (this.touch_business_installation) {
            this.routerPush(
              `/customer/${this.$store.state.basket.customerId}/sales-dashboard/choose-product`,
            );
          } else {
            this.routerPush('/choose-product');
          }
        });
    },
  },
};
</script>

<style>
.site-header {
  min-height: 65px;
  height: 65px;
}

@screen sm {
  .site-header {
    min-height: 122px;
    height: 122px;
  }
}
</style>
