<template>
  <touch-layout>
    <transition name="fade-in" mode="out-in">
      <loading v-if="loading" :key="1" :loading="true" style="flex: 1 1 0"></loading>
      <div
        v-else
        :key="2"
        class="flex flex-col-reverse sm:flex-row justify-around mx-auto w-full h-full"
        style="max-width: 1600px"
      >
        <div class="flex-1 flex flex-col justify-around">
          <div style="max-width: 450px" class="p-4 mx-auto">
            <div
              class="text-lg xs:text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-none"
            >
              <span class="font-medium">{{ thankYouHeadingText }}</span>
            </div>
            <div class="my-10">
              <div class="mb-3">
                <span class="font-medium text-base" v-html="thankYouSubHeadingText"></span>
              </div>
              <div class="mb-3 leading-relaxed">
                {{ thankYouText }}
              </div>
              <div>Got a question? Please call us on {{ branded_mode_settings.phone }}</div>
            </div>
            <div>
              <router-link
                class="btn-action btn-lg"
                :to="branded_mode_settings.restart_url + '?more=Y'"
              >
                Design more products
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="flex-1 bg-contain bg-no-repeat bg-center"
          :style="{ backgroundImage: image }"
        ></div>
      </div>
    </transition>
  </touch-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      mode: 'branded',
      image: '',
      loading: false,
      thankYouHeadingText: 'Thank you for making an enquiry.',
      thankYouSubHeadingText: 'What happens next. <i class="fal fa-envelope"></i>',
      thankYouText: '',
      enquiryPageStyling: undefined,
    };
  },
  computed: {
    ...mapState('auth', {
      isDealerNetworkMode: 'isDealerNetworkMode',
    }),
  },
  async created() {
    this.loading = true;
    this.mode = this.isDealerNetworkMode ? 'dealer' : 'branded';
    this.thankYouText = this.isDealerNetworkMode
      ? 'You will receive an e-mail containing a summary of your enquiry and we will pass your details and requirements to local members of our installer network to offer you an indicative quotation for the supply and installation of your desired products.'
      : "You will receive an instant email containing a summary of your enquiry. Don't forget to check you spam folder unfortunately that's where it may end up. Once we have reviewed your enquiry a member of our team will contact your shortly";
    this.enquiryPageStyling = (await this.getCompanyStyles()).enquiryPageStyling;
    this.setCustomPage();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getCompanyStyles: 'style/getCompanyStyles',
    }),
    setCustomPage() {
      this.thankYouHeadingText = this.enquiryPageStyling.thankYouHeadingText
        ? this.enquiryPageStyling.thankYouHeadingText
        : this.thankYouHeadingText;
      this.thankYouSubHeadingText = this.enquiryPageStyling.thankYouSubHeadingText
        ? this.enquiryPageStyling.thankYouSubHeadingText
        : this.thankYouSubHeadingText;
      this.thankYouText = this.enquiryPageStyling.thankYouText
        ? this.enquiryPageStyling.thankYouText
        : this.thankYouText;
      const defaultImagePath = this.$t(`/images/${this.mode}-thanks.png`);
      this.image = `url(${this.enquiryPageStyling?.completionImageUri ?? defaultImagePath})`;
    },
  },
};
</script>
