<template>
  <touch-layout page_override_class="retail-designer-choose-product">
    <loading :loading="loading">
      <div class="flex flex-col overflow-hidden mb-10">
        <choose-product-breadcrumbs
          :selected-group="selectedGroup"
          :selected-type="selectedType"
          :selected-product="selectedProduct"
          :group-count="groups.length"
          :type-count="types.length"
          @choose-type="productId = typeId = undefined"
          @choose-group="productId = groupId = typeId = undefined"
          @choose-product="productId = undefined"
        />
        <div class="bg-white flex-grow overflow-y-scroll scrolling-touch p-2 md:p-6">
          <div class="text-center px-3 lg:px-5">
            <div class="inline-block text-center font-medium bg-gray-200 p-2 px-8 rounded-lg">
              ALL DESIGNS VIEWED FROM OUTSIDE
            </div>
          </div>
          <div v-if="!selectedGroup">
            <!--            PICK A GROUP:-->
            <div class="flex flex-wrap w-full justify-center">
              <div
                v-for="(selection, index) in groups"
                :key="selection.productSelectionGroupId"
                class="flex flex-col w-full xs:w-1/2 sm:w-1/4 lg:w-1/5 p-3 lg:p-5"
              >
                <div
                  class="flex flex-col h-full bg-gray-200 rounded-lg cursor-pointer"
                  @click="groupId = selection.productSelectionGroupId"
                >
                  <div class="">
                    <div class="p-3 pb-0 md:pb-3">
                      <api-img :src="selection.imageUrl" class="mx-auto" />
                    </div>
                    <div class="text-center p-3 lg:p-5">
                      <div class="mb-3 lg:mb-5">
                        <div
                          :ref="`titleBox${index}`"
                          class="text-base md:text-lg lg:text-xl mb-3 lg:mb-5"
                          :data-titlebox="index"
                          v-html="selection.title"
                        ></div>
                        <div
                          v-for="(item, index) in description_list(selection.description)"
                          :key="index"
                          class="text-gray-700 text-xs md:text-sm"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-auto p-3 pt-0">
                    <button v-if="showDesignNames" class="btn-action lg:text-xs lg:py-3 lg:px-10 w-full">{{ selection.title }}</button>
                    <button v-else class="btn-action lg:text-xs lg:py-3 lg:px-10 w-full">Select</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="selectedGroup && !selectedType">
            <!--            PICK A Type:-->
            <div class="flex flex-wrap w-full justify-center">
              <div
                v-for="selection in types"
                :key="selection.productSelectionTypeId"
                class="flex flex-col w-full xs:w-1/2 sm:w-1/4 lg:w-1/5 p-3 lg:p-5"
              >
                <div
                  class="flex flex-col h-full bg-gray-200 rounded-lg cursor-pointer"
                  @click="typeId = selection.productSelectionTypeId"
                >
                  <div class="">
                    <div class="p-3 pb-0 md:pb-3">
                      <api-img :src="selection.imageUrl" class="mx-auto" />
                    </div>
                    <div class="text-center p-3 lg:p-5 flex flex-col">
                      <div class="mb-3 lg:mb-5">
                        <div
                          class="text-base md:text-lg lg:text-xl mb-3 lg:mb-5"
                          v-html="selection.title"
                        ></div>
                        <div
                          v-for="(item, index) in description_list(selection.description)"
                          :key="index"
                          class="text-gray-700 text-xs md:text-sm"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-auto p-3 pt-0">
                    <button v-if="showDesignNames" class="btn-action lg:text-xs lg:py-3 lg:px-10 w-full">{{ selection.title }}</button>
                    <button v-else class="btn-action lg:text-xs lg:py-3 lg:px-10 w-full">Select</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--          <choose-product-make-selection v-if="currentStep.isMakeSelection"-->
          <!--                                         @selected="selected($event)"-->
          <!--                                         :selections="currentStep.selections"-->
          <!--                                         :stage-slug="currentStep.stageInfo.title"/>-->

          <choose-product-select-product
            v-if="selectedGroup && selectedType && !productId"
            :products="products"
            :show-design-names="showDesignNames"
            @selected="productId = $event"
          />

          <choose-product-select-design
            v-if="selectedGroup && selectedType && selectedProduct"
            :product-id="productId"
            :show-design-names="showDesignNames"
            @selected="selectedDesign(productId, $event)"
          />
        </div>
      </div>
    </loading>
    <div class="flex flex-col mt-auto w-full bg-white fixed bottom-0 left-0 right-0">
      <div class="text-center flex justify-center">
        <div class="text-xs hidden 2xs:flex md:text-base lg:text-lg p-3 flex-col justify-around">
          Products and data powered by
        </div>
        <div class="flex flex-col justify-around p-1">
          <a
            :href="$t(store_url)"
            target="_blank"
            class="inline-flex bg-white border border-solid border-gray-400 rounded p-1"
          >
            <div class="p-1">
              <img src="/images/touch-logo.svg" width="50px" />
            </div>
            <div class="p-1 text-2xs">By BM Group</div>
          </a>
        </div>
      </div>
    </div>
  </touch-layout>
</template>

<script>
import ChooseProductBreadcrumbs from '@/components/branded/chooseProduct/ChooseProductBreadcrumbs.vue';
import ChooseProductSelectDesign from '@/components/branded/chooseProduct/ChooseProductSelectDesign.vue';
import ChooseProductSelectProduct from '@/components/branded/chooseProduct/ChooseProductSelectProduct.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    'choose-product-breadcrumbs': ChooseProductBreadcrumbs,
    // 'choose-product-make-selection': ChooseProductMakeSelection,
    'choose-product-select-design': ChooseProductSelectDesign,
    'choose-product-select-product': ChooseProductSelectProduct,
  },
  data() {
    return {
      groupId: undefined,
      typeId: undefined,
      productId: undefined,
      groups: [],
      types: [],
      products: [],
      currentStepIndex: 0,
      steps: [],
      loading: false,
      showDesignNames: undefined
    };
  },
  computed: {
    selectedGroup() {
      try {
        return this.groups.find((group) => group.productSelectionGroupId === this.groupId).title;
      } catch (exception) {
        return undefined;
      }
    },
    selectedType() {
      try {
        return this.types.find((type) => type.productSelectionTypeId === this.typeId).title;
      } catch (exception) {
        return undefined;
      }
    },
    selectedProduct() {
      try {
        const foundProduct = this.products.find((product) => product.productId === this.productId);
        return foundProduct.retailName || foundProduct.name;
      } catch (exception) {
        return undefined;
      }
    },
    currentStep() {
      const response = this.steps[this.currentStepIndex];

      if (response === undefined) {
        return false;
      }

      response.isMakeSelection = !!response.selections;
      response.isChooseProduct = !!response.products && response.products.length > 1;
      response.isChooseDesign = !!response.products && response.products.length === 1;

      if (response.stageInfo === null) {
        response.stageInfo = {
          blurb: `Please select for STAGE ${this.currentStepIndex + 1}`,
          title: `STAGE ${this.currentStepIndex + 1}`,
        };

        if (response.isChooseDesign) {
          response.stageInfo = {
            blurb: `Please select a ${response.products[0].retailName}`,
            title: response.products[0].retailName,
          };
        }
      }

      return response;
    },
    currentStepNumber() {
      return this.currentStepIndex + 1;
    },
  },
  watch: {
    async groupId(groupId) {
      if (!groupId) {
        this.types = [];
        return;
      }

      this.types = (await this.loadTypes({ groupId }))
        .map((type) => type.types)
        .sort((a, b) => a.listOrder - b.listOrder);

      if (this.types.length === 1) {
        this.typeId = this.types[0].productSelectionTypeId;
      }
    },
    async typeId(typeId) {
      if (!typeId) {
        this.products = [];
        return;
      }

      this.products = (await this.loadProducts({ typeId, groupId: this.groupId }))
        .map((product) => product.products)
        .sort((a, b) => a.listOrder - b.listOrder);

      if (this.products.length === 1) {
        this.productId = this.products[0].productId;
      }
    },
  },
  created() {
    window.addEventListener('resize', this.evenOutTitleBoxHeights);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.evenOutTitleBoxHeights);
  },
  async mounted() {
    this.groups = (await this.loadGroups(undefined))
      .map((group) => group.groups)
      .sort((a, b) => a.listOrder - b.listOrder)
      .filter(x => x)
    if (this.groups.length === 0) {
      this.alertBox().fire({
        title: 'No Products Found',
        text: this.$store.state.user.customer.company.name + ' needs to activate at least 1 product',
        icon: 'error',
      });
    } else if (this.groups.length === 1) {
      this.groupId = this.groups[0].productSelectionGroupId;
    }
    this.$nextTick(() => {
      this.evenOutTitleBoxHeights();
    });
    this.showDesignNames = this.$store.state.branded.showDesignNames;
  },
  methods: {
    ...mapActions({
      loadGroups: 'touch/processingGetAvailableProductSelectionGroups',
      loadTypes: 'touch/processingGetAvailableProductSelectionTypes',
      loadProducts: 'touch/processingGetAvailableProductsToSelect',
    }),
    evenOutTitleBoxHeights() {
      const titleBoxes = document.querySelectorAll('[data-titlebox]');
      let maxHeight = 0;
      titleBoxes.forEach((titleBox) => {
        const height = titleBox.offsetHeight;
        maxHeight = Math.max(maxHeight, height);
        // eslint-disable-next-line no-param-reassign
        titleBox.style.height = `${maxHeight}px`;
      });
    },
    description_list(description) {
      if (description) {
        return description.split('\n');
      }
      return [];
    },
    resetToStep(stepNumber) {
      if (this.currentStepIndex === stepNumber - 1) {
        // Do nothing we are on that step
        return;
      }

      this.currentStepIndex = stepNumber - 1; // reset pointer
      this.steps = this.steps.slice(0, stepNumber); // Remove future steps
    },
    createFakeProductStep(productIndex) {
      const currentState = JSON.parse(JSON.stringify(this.steps[this.currentStepIndex]));
      const nextStepIndex = this.currentStepIndex + 1;

      this.$set(this.steps, nextStepIndex, currentState);
      this.$set(this.steps[nextStepIndex], 'selected', currentState.selected);
      this.$set(this.steps[nextStepIndex], 'stepNumber', nextStepIndex + 1);
      this.$set(this.steps[nextStepIndex], 'products', [currentState.products[productIndex]]);
      this.$set(
        this.steps[nextStepIndex].stageInfo,
        'title',
        this.steps[nextStepIndex].products[0].retailName,
      );
      this.$set(
        this.steps[nextStepIndex].stageInfo,
        'blurb',
        `Please select a ${this.steps[nextStepIndex].products[0].retailName}`,
      );

      this.currentStepIndex = nextStepIndex;
    },
    async selected(selectionId) {
      const currentState = this.steps[this.currentStepIndex];
      const nextStepIndex = this.currentStepIndex + 1;

      this.$set(
        this.steps,
        nextStepIndex,
        await this.$store.dispatch('touch/productSelectionStage', [
          ...currentState.selected,
          selectionId,
        ]),
      );
      this.$set(this.steps[nextStepIndex], 'selected', [...currentState.selected, selectionId]);
      this.$set(this.steps[nextStepIndex], 'stepNumber', nextStepIndex + 1);

      this.currentStepIndex = nextStepIndex;
    },
    brandedItemLink(itemKey) {
      return this.$store.getters['branded/brandedItemLink'].replace(':id', itemKey);
    },
    selectedDesign(productId, designId) {
      this.loading = true;

      this.$store
        .dispatch('basket/addDesign', {
          designId,
          productId,
        })
        .then((itemKey) => {
          this.routerPush(this.brandedItemLink(itemKey));
        });
    },
  },
};
</script>
