export default {
  ddStatus: {
    NOT_SETUP_NULL: null,
    NOT_SETUP: 0,
    ACTIVE: 1,
    IN_ACTIVE: 2,
    ERROR: 3,
    PENDING: 4,
    OTHER: 5
  },
  ReportType: {
    'None': 0,
    'Trade Quote': 1,
    'Trade Order Confirmation': 2,
    'Trade Quote Requote': 3,
    'Trade Order Requote': 4,
    'Enquiry': 5,
    'Manual Survey': 6,
    'Trade Order': 7,
    'Price Request': 8,
    'Item Diagnostic': 9,
    'Fabricator Quote': 10,
    'Glass Order': 11,
    'Work Order': 12,
    'Cutting List': 13,
    'B and Q Estimate': 14,
    'B and Q Quote': 15,
  },
  applicationStatus: {
    PENDING: 0,
    VERIFIED: 1,
    ACCEPTED: 2,
    DECLINED: 3
  },
  brandedMode: {
    ENABLED: 0,
    FABRICATOR_ENABLED: 1,
    CUSTOMER_ENABLED: 2
  },
  discountLimitTo: {
    NONE: 0,
    LIMITED_TO_SPECIFIC_CUSTOMERS: 1,
  },
  discountType: {
    PERCENTAGE: 0,
    FIXED: 1,
  },
  discountPerOrderOrItem: {
    ORDER: 0,
    ITEM: 1,
  },
  discountStatus: {
    DISABLED: 0,
    ENABLED: 1,
    EXPIRED: 2,
    REDEEMED: 3,
  },
  customerStatus: {
    LIVE: 1,
    ON_STOP: 2,
    ARCHIVED: 3,
  },
  ImageTypeEnum: {
    quotePDFFirstImage: 1,
    quotePDFLastImage: 2,
    emailEquiryImage: 3,
  },
  ContractImportStateEnum: {
    NONE: 0,
    READY_TO_IMPORT: 1,
    IMPORTED: 2,
  },
  EnquiryStatus: {
    NONE: 0,
    UNASSIGNED: 1,
    ASSIGNED: 2,
    ACCEPTED: 3,
    REFUSED: 4,
    CLOSED: 5,
    COMPLETED: 6,
    EXPIRED: 7,
  },
  DealerStatus: {
    NONE: 0,
    AWAITINGACTION: 1,
    ACCEPTED: 2,
    QUOTED: 3,
    REFUSED: 4,
    EXPIRED: 5,
  },
  ProductStatus: {
    NONE: 0,
    INACTIVE: 1,
    LEAD_GEN_ONLY: 2,
    ACTIVE: 3,
  },
  JobLineItemInputType: {
    None: 0,
    FenestrationEngine: 1,
    ImportedLogikal: 2,
    Manual: 3,
    ExtraItem: 4,
    StockItem: 5,
    Pack: 6,
  },
  CompanyType: {
    None: 0,
    Organisation: 1,
    Customer: 2,
    Supplier: 3,
    Carrier: 4,
    Branch: 5,
  },
  ContactRoles: {
    None: 0,
    Director: 1,
    Manager: 2,
    Processor: 4,
    SalesPerson: 8,
    Surveyor: 16,
    Fitter: 32,
  },
  DeliveryType: {
    None: 0,
    Direct: 1,
    ViaBranch: 2,
    ByCarrier: 3,
  },
  DeliveryDays: {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 4,
    Thursday: 8,
    Friday: 16,
    Saturday: 32,
    Sunday: 64,
  },
  RackLocationTypes: {
    BASE: 0,
    CUSTOMER: 1,
    VEHICLE: 2,
    CARRIER: 3,
  },
  RackStatus: {
    NONE: 0,
    ACTIVE: 1,
    EMPTY: 2,
    SECONDED: 3,
    QUARANTINED: 4,
    REPAIR: 5,
    DEAD: 6,
  },

  PaymentAttemptStatus: {
    PENDING: 0,
    SUCCESS: 1,
    FAIL: 2,
  },

  PaymentProcessType: {
    NONE: 0,
    STRIPE: 1,
    FIRST_DATA: 2,
  },

  PaymentStatus: {
    NOT_REQUIRED: 0,
    AWAITING: 1,
    FAILED: 2,
    SUCCESS: 3,
  },

  IssueSeverityEnum: {
    INFORM: 0,
    WARN: 1,
    RESTRICT: 2,
    PROHIBIT: 3,
  },

  IssueSeverityEnumLabel: {
    INFORM: 'Inform',
    WARN: 'Warn',
    RESTRICT: 'Restrict',
    PROHIBIT: 'Prohibit',
  },

  contactPositions: {
    DIRECTOR: 0,
    MANAGER: 1,
    STAFF: 2,
  },

  contactDepartments: {
    MARKETING: 0,
    SALES: 1,
    SALES_ADMIN: 2,
    PRODUCTION: 3,
    DELIVERIES: 4,
    INSTALLATIONS: 5,
    ACCOUNTS: 6,
    CUSTOMER_SERVICE: 7,
    PURCHASING: 8,
  },

  optionSetByTypes: {
    DEFAULT: 0,
    AUTOMATION: 1,
    USER: 2,
    CLONED: 3,
    PREVIOUS_SELECTION_INVALIDATED: 4,
  },

  productType: {
    NONE: 0,
    SERVER: 1,
    SERVER_ACCESS_SERVER: 2,
    SERVER_ACCESS_VENDOR: 3,
    SERVER_ACCESS_DEVELOPER: 4,
    BUSINESS: 5,
    PORTAL: 6,
    DEALER: 7,
    ECOMMERCE: 8,
    FACTORY: 9,
    VENDOR: 10,
    VENDOR_LINK: 11,
    COURIER: 12,
    REGISTER: 13,
    SUPPORT: 14,
    HOSTING: 15,
    DATA_BUILD: 16,
    // CONNECT: 17,
    VENDOR_USER: 17,
    COURIER_USER: 18,
    BRANDED_LINK: 19,
    BRANDED: 20,
    CONNECT_COMPONENT_PORTAL: 21,
    CONNECT_COMPONENT_ADMIN: 22,
    CONNECT_PRODUCT_VENDOR: 23,
    CONNECT_PRODUCT_ADMIN: 24,
    RACKMANAGER: 25,
    INTEGRATE: 26,
    BRANCHES: 27,
  },
  visibilityType: {
    STANDARD: 0,
    ONLY_FOR_DESIGN_SELECTION: 1,
  },
  orderStatus: {
    NONE: 0,
    PENDING: 1,
    PLACED: 2,
    CANCELLED: 3,
  },
  orderStatusSaveStates: {
    NOT_APPLICABLE: 0,
    NOT_STARTED: 1,
    STARTED: 2,
    FAILED: 3,
    COMPLETED: 4,
  },
  quoteStatus: {
    NONE: 0,
    CONVERTED: 1,
  },
  quoteEmailStatusType: {
    NOTSENT: 0,
    PENDING: 1,
    FAILED: 2,
    SENT: 3,
  },
  enquiryStatus: {
    NONE: 0,
    UNASSIGNED: 1,
    ASSIGNED: 2,
    ACCEPTED: 3,
    REFUSED: 4,
    DEAD: 5,
    CONVERTED_TO_QUOTE: 6,
    Expired: 7,
  },
  documentType: {
    NONE: 0,
    TERMS_AND_CONDITIONS: 1,
    PRIVACY_POLICY: 2,
    RESOURCE_FILE: 3,
    RESOURCE_VIDEO: 4,
  },
  documentVideoType: {
    NONE: 0,
    YOUTUBE: 1,
    VIMEO: 2,
  },
  imageType: {
    EXTERNAL: 1,
    INTERNAL: 2,
    PLAN: 3,
    GLASS_SCHEMATIC: 4,
    FOLDING_SASH_VIEW: 5,
    SASH_PLAN_VIEW: 6,
  },

  salesSectorType: {
    NONE: 0,
    TRADE: 1,
    RETAIL: 2,
    COMMERCIAL: 3,
    NEWBUILD: 4,
  },

  enquirySource: {
    DEALER_NETWORK: 1,
    BRANDED_MODE: 2,
  },

  noteType: {
    INTERNAL: 1,
    SUPPLIER: 2,
    CUSTOMER: 3,
  },

  priceRateType: {
    NONE: 0,
    EACH: 1,
    METER_SQUARED: 2,
    PER_METER: 3,
    PERCENT: 4,
    PER_KG: 5,
  },

  anomalyType: {
    SIMPLE_VALUE: 0,
    LIST: 1,
    GRID: 2,
  },

  fittingType: {
    SUPPLY_AND_FIT: 1,
    SUPPLY_ONLY: 2,
  },

  unitOfMeasure: {
    NONE: 0,
    UNIT: 1,
    LENGTH: 2,
    WIDTH: 3,
    HEIGHT: 4,
    AREA: 5,
    PERIMETER: 6,
    VOLUME: 7,
    FITTING_DAYS: 8,
  },

  inputType: {
    NONE: 0,
    FENESTRATION: 1,
    IMPORTED: 2,
    MANUAL: 3,
    CUSTOMER: 4,
    STOCK_ITEM: 5,
  },

  connectType: {
    COMPONENTS: 0,
    PRODUCTS: 1,
    STOCK: 2,
  },

  licenseStatusType: {
    NONE: 0,
    ENABLED: 1,
    DISABLED: 2,
  },

  generalLicenseStatus: {
    NONE: 0,
    ENABLED: 1,
    DISABLED: 2,
  },

  errorType: {
    GENERIC: 0,
    COOKIES_DISABLED: 1,
    404: 2,
  },
};
