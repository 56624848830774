<template>
  <touch-layout :page_override_class="{ 'trade-designer-choose-product': true }">
    <template #header_page_breadcrumb>
      <Breadcrumbs
        :routes="[
          ['Dashboard', '/'],
          ['Customer Management', '/customer'],
          ['Sales Dashboard', '/customer/' + customer.customerId + '/sales-dashboard'],
          ['Choose Product'],
          [description],
        ]"
      />
    </template>
    <template #header_page_title>
      <div>
        <template v-if="item && currentImageType">
          <template v-if="!somethingIsSelected">
            {{ description }}
            <HelpPoint v-if="isBowOrBayWindow" class="ml-2" freshdesk-article-id="101000456240" />
          </template>
          <template v-if="somethingIsSelected">Edit Individual Components</template>
        </template>
      </div>
    </template>
    <Designer
      v-if="item"
      ref="designer"
      :customer="customer"
      :item="item"
      :something-is-selected="somethingIsSelected"
      :current-image-type="currentImageType"
      @setCurrentItem="setCurrentItem"
      @setCurrentImageTypeParent="currentImageType = $event"
      @setBreakdown="setBreakdown"
    >
      <template v-if="isExtras" #extras>
        <animation-staggered-fade
          v-if="$refs.designer && !$refs.designer.somethingIsSelected && $store.state.auth.accountSettings.showfitting"
          v-show="$refs.designer.showSpecificationPanel"
        >
          <div key="extras_box" class="border mb-3 shadow rounded">
            <button
              class="p-2 text-base w-full flex justify-between"
              :class="{ 'border-b': $refs.designer.extras_open }"
              @click="$refs.designer.extras_open = !$refs.designer.extras_open"
            >
              <strong> Extras </strong>
              <i v-if="!$refs.designer.extras_open" class="fal fa-arrow-circle-down mt-1"></i>
              <i v-else class="fal fa-arrow-circle-up mt-1"></i>
            </button>
            <div v-show="$refs.designer.extras_open" class="p-3 pb-0">
              <designer-extras
                key="'designer-extras'"
                data-index="designer-extras"
                :item="$refs.designer.item"
                :extras="$refs.designer.extrasForThisItem"
                :item-id="$route.params.id"
                @delete-line-item="$refs.designer.deleteLineItem"
                @setBreakdown="setBreakdown"
              />
            </div>
          </div>
        </animation-staggered-fade>
      </template>
    </Designer>
  </touch-layout>
</template>

<script>
import Designer from '@/components/shared/designer/Designer.vue';
import Breadcrumbs from '@/components/shared/Breadcrumbs.vue';
import HelpPoint from '@/components/shared/HelpPoint.vue';
import DesignerExtras from '@/components/shared/designer/DesignerExtras.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    Designer,
    Breadcrumbs,
    HelpPoint,
    DesignerExtras,
  },
  props: {
    defaultDesignView: {
      default: undefined,
      type: Number,
    },
  },
  data() {
    return {
      customerId: undefined,
      description: undefined,
      customer: {
        customerId: null,
      },
      currentImageType: null,
      isExtras: false,
    };
  },
  computed: {
    ...mapState('basket', {
      item: 'currentItem',
    }),
    ...mapGetters('designer', {
      somethingIsSelected: 'somethingIsSelected',
    }),
    isBowOrBayWindow() {
      return (
        this.item.images.find((image) => image.type === window.enum.imageType.PLAN) !== undefined
      );
    },
  },
  async mounted() {
    this.customer = await this.customerGet(this.$route.params.customerId);
    this.$store.dispatch('basket/setSalesSectorType', this.customer.customer.salesSectorType);
    this.currentImageType = null;

    if (!this.$store.getters['basket/hasContract']) {
      this.routerPush('/new-quote');
      return;
    }

    this.$store.commit('designer/SET_OPEN_MENU_TYPE', 'specification');
    this.$store.commit('designer/CLEAR_SELECTED_COMPONENTS');
    this.$store.commit('designer/clearSpecificationGroup');
    this.$store.commit('designer/CLEAR_SHOW_SELECTION_FOR_HEADING_ID');

    const item = await this.$store.dispatch('basket/loadItem', {
      itemKey: this.$route.params.id,
      customerID: this.$route.params.customerId,
    });

    if (item) {
      await this.setCurrentItem(item);
      if (!this.customer.financial.omitPriceBreakdown) {
        await this.setBreakdown(item);
      }
      this.description = this.item.description;
    } else {
      this.alertBox().fire({
        title: 'Item not found.',
        showCancelButton: false,
        showConfirmButton: false,
        html: `
          <button id="back" class="btn m-1">Go Back</button>
          <button id="new-quote" class="btn-action m-1">New Quote</button>
          <button id="new-order" class="btn-action m-1">New Order</button>
        `,
        onBeforeOpen: () => {
          document.getElementById('back').addEventListener('click', () => {
            this.alertBox().close();
            this.$router.go(-1);
          });
          document.getElementById('new-quote').addEventListener('click', () => {
            this.routerReplace('/customer/' + this.customer.customerId + '/sales-dashboard/new-quote');
            this.alertBox().close();
          });
          document.getElementById('new-order').addEventListener('click', () => {
            this.routerReplace('/customer/' + this.customer.customerId + '/sales-dashboard/new-order');
            this.alertBox().close();
          });
        },
      });
      return;
    }

    if (typeof this.defaultDesignView !== 'undefined') {
      this.currentImageType = this.defaultDesignView;
    } else {
      this.currentImageType = this.item.images[0].type;
      if (
        this.item.images.find((image) => image.type === window.enum.imageType.PLAN) !== undefined
      ) {
        this.currentImageType = window.enum.imageType.EXTERNAL;
      }
    }

    const { groups } = await window.touch.customerGetAllExtraItems();
    const extras = [].concat(...groups.map((x) => x.items));
    this.isExtras =
      (extras.length &&
        ![this.enums.salesSectorType.TRADE, this.enums.salesSectorType.RETAIL].includes(
          this.customer.customer.salesSectorType,
        )) ||
      (this.customer.customer.salesSectorType === this.enums.salesSectorType.TRADE &&
        extras.filter((x) => x.type === this.enums.fittingType.SUPPLY_ONLY).length > 0) ||
      (this.customer.customer.salesSectorType === this.enums.salesSectorType.RETAIL &&
        extras.length > 0);
  },
  methods: {
    ...mapActions({
      customerGet: 'user/customerGet',
    }),
    async setCurrentItem(item) {
      this.$store.commit('basket/SET_CURRENT_ITEM', item);
    },
    async setBreakdown(item = this.$store.state.basket.currentItem) {
      if (!this.customer.financial.omitPriceBreakdown) {
        const { priceBreakdown, total } = await window.touch.processingGetItemPriceBreakdown(
        this.$route.params.customerId,
        item.contractId,
        item.jobKey,
        this.$route.params.id,
        this.$store.state.auth.processingLevel,
      );

      if (priceBreakdown) {
        item.priceBreakdown = priceBreakdown;
      }
      item.price = total;
      }
      this.setCurrentItem(item);
    },
  },
};
</script>
