<template>
  <div class="flex-1 flex flex-col">
    <div class="bg-green-100 border p-3 h-full">
      <div class="border-b font-bold mb-3 pb-3 flex justify-between">
        <div>Quote Email Notification</div>
      </div>
      <table class="w-full">
        <tbody>
          <tr>
            <td class="pb-1 w-32">Status:</td>
            <td class="pb-1 text-brand-primary">
              {{ $t(`quoteEmailStatus.${job.quoteEmailStatusType}`) }}
            </td>
          </tr>
          <tr>
            <td class="pb-1 w-32">Date:</td>
            <td
              v-if="
                job.quoteEmailStatusDate &&
                  job.quoteEmailStatusType === enums.quoteEmailStatusType.SENT
              "
              class="pb-1 text-brand-primary"
            >
              <span :title="formatHumanDate(job.quoteEmailStatusDate)">{{
                formatDate(job.quoteEmailStatusDate, "L HH:mm")
              }}</span>
            </td>
            <td v-else class="pb-1 text-brand-primary">N/A</td>
          </tr>
        </tbody>
      </table>

      <button class="btn-action w-full mt-2" @click="sendEmailNotification">
        Send Email Notification
      </button>
    </div>
  </div>
</template>

<script>
import dateHelper from '@/mixins/dateHelper';

export default {
  name: 'SendQuoteNotification',
  mixins: [dateHelper],
  props: ['job'],
  methods: {
    async sendEmailNotification() {
      await this.$store.dispatch('touch/sendTradeCustomerQuoteReadyEmail', {
        contractId: this.job.contractId,
        jobKey: this.job.jobKey,
      });
      this.$emit('reload');
    },
  },
};
</script>

<style scoped></style>
